import React, { useState } from 'react';
import { Layout, Typography, Divider } from 'antd';
import Doc from '../Sections/Doc';
import Export from '../Sections/Export';
import MermaidRender from '../Sections/MermaidRender';
import './style.css';
import Import from '../Sections/Import';

const { Title } = Typography;
const { Content, Header, Footer } = Layout;
const defaultCode = `graph TD
A[Christmas] -->|Get money| B(Go shopping)
B --> C{Let me think}
C -->|One| D[Laptop]
C -->|Two| E[iPhone]
C -->|Three| F[Car]
`;

function App() {
  const [Code, setCode] = useState(defaultCode);
  const [error, setError] = useState(false);

  return (
    <Layout>
      <Header>
        <Title className='title'>Chart Notion</Title>
      </Header>
      <Content style={{ margin: '0 40px' }}>
        <Divider orientation='left'>
          <Title level={4}>Import</Title>
        </Divider>
        <Import setCode={setCode} />

        <Divider orientation='left'>
          <Title level={4}>Live Editor</Title>
        </Divider>

        <MermaidRender
          Code={Code}
          setCode={setCode}
          error={error}
          setError={setError}
        />

        <Export Code={Code} error={error} />

        <Divider orientation='left'>
          <Title level={4}>Documentation</Title>
        </Divider>

        <Doc />
      </Content>
      <Footer style={{ textAlign: 'center' }}>Created by Eliott Ephrati</Footer>
    </Layout>
  );
}

export default App;
