/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { mermaidAPI } from 'mermaid';
import { Input, Row, Col } from 'antd';
import './style.css';

const { TextArea } = Input;

function MermaidRender({ Code, setCode, error, setError }) {
  const [diagram, setDiagram] = useState('');

  mermaidAPI.initialize({
    startOnLoad: false,
    flowchart: {
      htmlLabels: false
    }
  });

  useEffect(() => {
    try {
      // parse the data
      mermaidAPI.parse(Code);
      setError(false);
      // render
      const needsUniqueId = `render${Math.floor(
        Math.random() * 10000
      ).toString()}`;
      mermaidAPI.render(needsUniqueId, Code, svg => {
        setDiagram(svg);
      });
    } catch (e) {
      setError(true);
    }
  }, [Code]);

  return (
    <>
      <Row gutter={[16, 24]}>
        <Col xs={24} sm={24} md={12} lg={10} xl={10}>
          <TextArea
            className={error && 'error'}
            value={Code}
            autoSize={{ minRows: 15 }}
            onChange={evt => setCode(evt.target.value)}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={14} xl={14}>
          <div
            className='mermaid'
            dangerouslySetInnerHTML={{ __html: diagram }}
          />
        </Col>
      </Row>
    </>
  );
}

export default MermaidRender;
