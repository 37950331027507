export const docs = [
  {
    title: 'Flowchart',
    url: 'https://mermaid-js.github.io/mermaid/#/flowchart'
  },
  {
    title: 'Sequence Diagrams',
    url: 'https://mermaid-js.github.io/mermaid/#/sequenceDiagram'
  },
  {
    title: 'Class Diagram',
    url: 'https://mermaid-js.github.io/mermaid/#/classDiagram'
  },
  {
    title: 'State Diagram',
    url: 'https://mermaid-js.github.io/mermaid/#/stateDiagram'
  },
  {
    title: 'Gant Diagram',
    url: 'https://mermaid-js.github.io/mermaid/#/gantt'
  },
  {
    title: 'Pie Chart',
    url: 'https://mermaid-js.github.io/mermaid/#/pie'
  }
];
