import React, { useState } from 'react';
import { Input, Row, Col, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import './style.css';

function Import({ setCode }) {
  const [input, setInput] = useState('');

  function extractFromUrl() {
    const tempinput = input.split('code=')[1];
    let cleanedinput = decodeURIComponent(tempinput);
    cleanedinput = cleanedinput.replace(/;+/g, '\n');
    setCode(cleanedinput);
  }

  return (
    <Row>
      <Col span={24}>
        <Input
          value={input}
          type='text'
          onChange={e => setInput(e.target.value)}
        />
      </Col>
      <Col span={24} style={{ textAlign: 'center' }}>
        <Button
          className='importButton'
          type='primary'
          shape='round'
          icon={<DownloadOutlined />}
          size='medium'
          onClick={extractFromUrl}
        >
          Import Data
        </Button>
      </Col>
    </Row>
  );
}

export default Import;
