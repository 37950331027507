import React from 'react';
import { Button, message, Row } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import './style.css';

function Export({ Code, error }) {
  function generateURL() {
    let cleanedInput = Code;
    if (cleanedInput[cleanedInput.length - 1] === ';')
      cleanedInput = cleanedInput.substring(0, cleanedInput.length - 1);
    const finalUrl = `https://chart.hackedbrain.dev/diagram?code=${encodeURIComponent(
      cleanedInput
    )}`;
    copy(finalUrl);
    message.success('Successfully Copied to Clipboard');
  }

  return (
    <Row justify='center'>
      <Button
        className='exportButton'
        type='primary'
        shape='round'
        icon={<ExportOutlined />}
        size='medium'
        onClick={generateURL}
        disabled={error}
      >
        Copy Url for Notion
      </Button>
    </Row>
  );
}

export default Export;
