import React, { useState } from 'react';
import { Drawer, Button, Row, Col } from 'antd';
import { docs } from './util';
import './style.css';

function Doc() {
  const [isToggleVisible, setToggleVisible] = useState(false);
  const [currentUrl, setCurrentUrl] = useState('');

  function selectIframe(doc) {
    setCurrentUrl(doc.url);
    setToggleVisible(true);
  }

  return (
    <>
      <Row justify='center' gutter={[60, 24]}>
        {docs.map((doc, i) => {
          return (
            <Col key={i}>
              <Button
                onClick={() => {
                  selectIframe(doc);
                }}
              >
                {doc.title}
              </Button>
            </Col>
          );
        })}
      </Row>
      <Drawer
        title='Mermaid Documentation'
        placement='right'
        className='doc-drawer'
        closable
        onClose={() => {
          setToggleVisible(false);
        }}
        visible={isToggleVisible}
      >
        <iframe
          title='docFrame'
          frameBorder='0'
          width='100%'
          height='100%'
          src={currentUrl}
        />
      </Drawer>
    </>
  );
}

export default Doc;
